// import React, { useRef } from "react"
// import Img from "gatsby-image"
// import parse from "html-react-parser"
// import Layout from "../components/layout"
// import { Link } from "gatsby"
// import BackgroundImage from "gatsby-background-image"
// import useAbout from "../hooks/use-about"
// import useExploreSolution from "../hooks/use-explore_solution"
// import UnifiedImage from "../assets/images/image 30.png"
// import InnovationImage from "../assets/images/Group 15341.png"
// import { GlobalStyles, mediaBreakpoints } from "../styles/styles"
// import { newGlobalStyles, NewMediaBreakpoints } from "../styles/newstyles"
// import OurSolution from "../components/ourSolution"
// import HeroImageSection from "../components/heroImageSection"
// import styled from "@emotion/styled"
// import { css } from "@emotion/react"
// import SEO from "../components/SEO"
// import LeaderShip from "../components/leadership"
// import { useTrackVisibilityObserver } from "../hooks/use-trackVisibilityObserver"
// import "../styles/overflowScroll.css"
// import ScribeImg from "../assets/svg/Mask Group.svg";

// const { small, smallAndMedium, medium, xLarge, semiLarge } = NewMediaBreakpoints

// const DesignWrapper = styled.div`
//   width: 100%;
//   background-color: #47477D;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   @media ${small} {
//     flex-direction: column;
//   }
// `
// const DesignContainer = styled.div`
//   background-color: #47477d;
//   color: #fff;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   padding: 6.25rem 1rem;
// `
// const ProductWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   background-color: #fff;
//   color: #000;
//   @media ${small} {
//     flex-direction: column;
//   }
// `
// const ProductWrapper1 = styled.div`
//   display: flex;
//   justify-content: center;
//   background-color: #fff;
//   color: #000;
//   flex-direction: row-reverse;
//   @media ${small} {
//     flex-direction: column;
//   }
// `
// const ProductTextWrapper = styled.div`
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   padding: 8rem 6rem;
//   -webkit-box-pack: center;
//   justify-content: center;
//   align-items: flex-start;
//   padding-top: 8rem;
//   @media ${medium} {
//     width: 100%;
//     padding: 3.15rem 5%;
//   }
//   @media ${small} {
//     width: 100%;
//     padding: 3.15rem 5%;
//   }
// `
// const ProductImageWrapper = styled.div`
//   width: 50%;
//   @media ${medium} {
//     width: 100%;
//   }
//   @media ${small} {
//     width: 100%;
//   }
// `

// const ProductImage = css`
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: center center;
//   opacity: 1;
//   transition: opacity 500ms ease 0s;
// `
// const DesignText = css`
//   color: #fff;
//   text-align: center;
//   margin-bottom: 3rem;
// `
// const ProductText = css`
//   font-family: Mulish;
//   font-size: 16px;
//   line-height: 1.88;
//   width: 100%;
//   max-width: 28.75rem;
//   margin-bottom: 0;
//   margin-top: 1rem;
// `

// const DescriptionTile = styled.div`
//   width: 90%;
//   text-align: left;
//   background-color: ${props =>
//     props && props.bgColor ? props.bgColor : "#5451ff"};
//   display: flex;
//   align-items: center;
//   justify-content: ${props =>
//     props && props.index === 1 ? "flex-start" : "flex-end"};
//   padding: 0 3.9375rem;
//   transform: ${props =>
//     props && props.index === 1 ? "translateY(0)" : "translateY(0)"};
//   @media ${medium} {
//     padding: 0 1.5rem;
//   }
//   @media ${small} {
//     width: 100%;
//     transform: translateY(0);
//     justify-content: center;
//     padding: 1.75rem 5%;
//     height: auto;
//   }
// `
// const DescriptionTextWrapper = styled.div`
//   @media ${medium} {
//     width: 100%;
//   }
//   @media ${small} {
//     width: 100%;
//   }
// `
// const DescriptionTextWrapper1 = styled.div`
//   width: 100%;
//   @media ${medium} {
//     width: 100%;
//   }
//   @media ${small} {
//     width: 100%;
//   }
// `

// const AboutDescTileHeader = styled.h3`
//   color: #fff;
//   margin-bottom: 3.125rem;
//   text-align: center;
//   @media ${medium} {
//     margin-bottom: 2rem;
//   }
//   @media ${small} {
//     margin-bottom: 3.125rem;
//     font-size: 30px;
//     letter-spacing: 0.2px;
//   }
// `
// const GetInTouchWrapper = styled.div`
//   width: 100%;
//   background-color: #242424;
//   position: relative;
//   display: flex;
//   height: 44.0625rem;
//   @media ${small} {
//     flex-direction: column;
//     height: auto;
//   }
// `
// const GetInTouchTileContainer = styled.div`
//   width: 50%;
//   height: 100%;
//   display: flex;
//   justify-content: flex-end;
//   @media ${small} {
//     width: 100%;
//     padding: 7.5rem 5%;
//     height: auto;
//   }
// `
// const GetInTouchTagline = styled.h3`
//   width: 36rem;
//   color: #fff;
//   padding-bottom: 2rem;
//   & p {
//     margin: 0;
//   }
//   @media ${medium} {
//     width: 22rem;
//   }
//   @media ${semiLarge} {
//     width: 26rem;
//   }
//   @media ${small} {
//     width: 100%;
//     padding-bottom: 5.0625rem;
//     text-align: left;
//     & p {
//       font-size: 30px;
//     }
//   }
// `
// const GetInTouchImage = css`
//   height: 37.56rem;
//   width: 70%;
//   max-width: 30.19rem;
//   margin-right: 4.56rem;
//   transform: translateY(3.31rem);
//   @media ${small} {
//     width: 100%;
//     transform: translateY(0);
//     padding: 3rem 1.2rem;
//     margin-right: 0;
//     max-width: none;
//   }
// `
// const OurSolutionHeader = styled.h2`
//   width: 100%;
//   text-align: center;
//   margin-bottom: 4rem;
//   @media ${small} {
//     font-size: 32px;
//     margin-right: 0;
//   }
// `
// const OurSolutionWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   align-items: center;
// `

// const ExploreSolutionImage = styled(BackgroundImage)`
//   background-size: cover;
//   height: 100%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// `

// const ExploreSolutionwrapper = styled("div")`
//   width: 100%;
//   height: 31.25rem;
// `
// const ExploreSolutionHeader = styled("h3")``

// const createAboutDescArray = data => {
//   let aboutDecArray = []
//   if (data) {
//     if (data.about_firsttile_title && data.about_firsttile_text) {
//       let firstTile = {
//         aboutDescTitle: data.about_firsttile_title,
//         aboutDescContent: data.abstraction_text,
//         tileColor: data.about_firsttile_color
//           ? data.about_firsttile_color
//           : "#5451ff",
//       }
//       aboutDecArray.push(firstTile)
//     }
//     // if (data.about_secondtile_title && data.about_secondtile_text) {
//     //   let secondTile = {
//     //     aboutDescTitle: data.about_secondtile_title,
//     //     aboutDescContent: data.about_secondtile_text,
//     //     tileColor: data.about_secondtile_color
//     //       ? data.about_secondtile_color
//     //       : "47477d",
//     //   }
//     //   aboutDecArray.push(secondTile)
//     // }
//   }
//   return aboutDecArray
// }

// const getExploreSolutionsArray = (solArray, idArray) => {
//   let array = []
//   idArray &&
//     idArray.map(id => {
//       const result = solArray.filter(data => data.wordpress_id === id)
//       array = [...array, ...result]
//     })
//   return array
// }

// const About = props => {
//   const aboutRef = useRef(null)

//   const [inView] = useTrackVisibilityObserver(aboutRef, {
//     threshold: 0,
//   })
//   const aboutData = useAbout()
//   const exploreSolutions = useExploreSolution()
//   const exploreSolutionsArray = getExploreSolutionsArray(
//     exploreSolutions,
//     aboutData.existing_solutions
//   )
//   const aboutDescArray = createAboutDescArray(aboutData)

//   return (
//     <Layout
//       theme="light"
//       duration="1s"
//       pathname="/about"
//       bottomContactPage={true}
//       punchLine="Contact"
//       sentence="Let’s connect to discuss partnership or career opportunities."
//     >
//       <SEO
//         title="Piktorlabs | About"
//         description="About piktor labs"
//         pathname="/about"
//         article={false}
//       />
//       {aboutData.heroimage && (
//         <newGlobalStyles.StyledSection
//           css={css`
//             color: black;
//             font-weight: 600;
//             position: relative;
//           `}
//         >
//           <img src={ScribeImg} style={{position: 'absolute', top: '50%', left: '50%', width: '100rem', height: '80rem', transform: 'translate(-50%, -50%)'}} />
//           <HeroImageSection
//             imageSrc={aboutData.heroimage}
//             imageBgSource={aboutData.heromobileimage}
//             text={
//               aboutData?.hero_title
//                 ? aboutData.hero_title
//                 : "We are a product innovation lab"
//             }
//             theme={"light"}
//             width="80%"
//           ></HeroImageSection>
//         </newGlobalStyles.StyledSection>
//       )}
//       {aboutDescArray?.length > 0 && (
//         <newGlobalStyles.StyledSection bgcolor="#242424">
//           <DesignContainer>
//             <h3 css={DesignText}>{aboutData?.about_firsttile_title}</h3>
//             <DesignWrapper>
//               {aboutDescArray?.length > 0 &&
//                 aboutDescArray.map((item, index) => {
//                   return (
//                     <DescriptionTile
//                       bgColor={item.tileColor}
//                       key={index}
//                       index={index}
//                     >
//                       {aboutDescArray?.length !== 1 ? (
//                         <DescriptionTextWrapper>
//                           {/* <AboutDescTileHeader>
//                           {item.aboutDescTitle}
//                         </AboutDescTileHeader> */}
//                           <newGlobalStyles.body1
//                             css={css`
//                               color: #fff;
//                               text-align: center;
//                             `}
//                           >
//                             <div className="abstraction">{parse(item.aboutDescContent)}</div>
//                           </newGlobalStyles.body1>
//                         </DescriptionTextWrapper>
//                       ) : (
//                           <DescriptionTextWrapper>
//                             {/* <AboutDescTileHeader>
//                               {item.aboutDescTitle}
//                             </AboutDescTileHeader> */}
//                             <newGlobalStyles.body1
//                               css={css`
//                               color: #fff;
//                               text-align: center;
//                             `}
//                             >
//                               <div className="abstraction">{parse(item.aboutDescContent)}</div>
//                             </newGlobalStyles.body1>
//                           </DescriptionTextWrapper>
//                         )}
//                     </DescriptionTile>
//                   )
//                 })}
//             </DesignWrapper>
//           </DesignContainer>
//         </newGlobalStyles.StyledSection>
//       )}
//       <ProductWrapper>
//         <ProductImageWrapper>
//           <img
//             src={UnifiedImage}
//             alt="UnifiedImage"
//             css={css`
//               width: 100%;
//               height: 100%;
//               object-fit: cover;
//             `}
//           />
//         </ProductImageWrapper>
//         <ProductTextWrapper>
//           <h3>{aboutData?.about_thirdtile_title}</h3>
//           <p className="productData" css={ProductText}>{parse(aboutData?.unified_design_culture)}</p>
//         </ProductTextWrapper>
//       </ProductWrapper>
//       <ProductWrapper1>
//         <ProductImageWrapper>
//           <Img css={ProductImage} fluid={aboutData?.about_fourthtile_image} />
//         </ProductImageWrapper>
//         <ProductTextWrapper>
//           <h3>{aboutData?.about_fourthtile_title}</h3>
//           <p className="productData" css={ProductText}>{parse(aboutData?.design_driven)}</p>
//         </ProductTextWrapper>
//       </ProductWrapper1>
//       <ProductWrapper>
//         <ProductImageWrapper>
//           <img src={InnovationImage} alt="InnovationImage" css={css`width: 100%; height: 100%; object-fit: cover;`} />
//         </ProductImageWrapper>
//         <ProductTextWrapper>
//           <h3>{aboutData?.about_fifthtile_title}</h3>
//           <p className="productData" css={ProductText}>{parse(aboutData?.innovation_arm)}</p>
//         </ProductTextWrapper>
//       </ProductWrapper>
//       {aboutData?.get_in_touch_image && aboutData?.get_in_touch_text && (
//         <newGlobalStyles.StyledSection bgcolor="#242424">
//           <GetInTouchWrapper>
//             <GetInTouchTileContainer
//               css={css`
//                 @media ${small} {
//                   height: auto;
//                   padding: 0;
//                 }
//               `}
//             >
//               <Img
//                 css={GetInTouchImage}
//                 fluid={aboutData?.get_in_touch_image}
//               />
//             </GetInTouchTileContainer>
//             <GetInTouchTileContainer
//               css={css`
//                 flex-direction: column;
//                 padding: 3.125rem 3.125rem 3.125rem 0;
//                 @media ${small} {
//                   height: auto;
//                 }
//               `}
//             >
//               {aboutData && aboutData.get_in_touch_text && (
//                 <GetInTouchTagline css={css`font-size: 24px;`}>
//                   <div className="storiesData">{parse(aboutData.get_in_touch_text)}</div>
//                 </GetInTouchTagline>
//               )}
//               {aboutData && aboutData.scribe_ending && (
//                 <GetInTouchTagline css={css`font-size: 35px; padding-bottom: 3rem`}>
//                   {parse(aboutData.scribe_ending)}
//                 </GetInTouchTagline>
//               )}
//               <newGlobalStyles.body1
//                 css={css`
//                   color: #ffffff;
//                 `}
//               >
//                 Got a business problem to solve?
//               </newGlobalStyles.body1>

//               <Link
//                 css={css`
//                   width: 10.1rem;
//                 `}
//                 to="/contact"
//               >
//                 <newGlobalStyles.submitButton
//                   css={css`
//                     color: #2d2f44;
//                     width: fit-content;
//                     margin-top: 1.5rem;
//                   `}
//                 >
//                   GET IN TOUCH
//                 </newGlobalStyles.submitButton>
//               </Link>
//             </GetInTouchTileContainer>
//           </GetInTouchWrapper>
//         </newGlobalStyles.StyledSection>
//       )}
//       {/* {exploreSolutionsArray?.length > 0 && (
//         <newGlobalStyles.StyledSection bgcolor="#fff">
//           <newGlobalStyles.DescriptionSection
//             mobMaxWidth="100%"
//             mobWidth="100%"
//             css={css`
//               padding-top: 9rem;
//               padding-bottom: 6.25rem;
//               @media ${small} {
//                 padding: 6.25rem 5% 6.25rem 5%;
//               }
//             `}
//           >
//             <OurSolutionHeader>Our solutions</OurSolutionHeader>
//             <OurSolutionWrapper>
//               {exploreSolutionsArray?.length > 0 &&
//                 exploreSolutionsArray.map((item, index) => {
//                   return <OurSolution data={item} key={index} />
//                 })}
//             </OurSolutionWrapper>
//           </newGlobalStyles.DescriptionSection>
//         </newGlobalStyles.StyledSection>
//       )} */}
//       {/* {aboutData?.explore_more_image && aboutData?.explore_more_text && (
//         <newGlobalStyles.StyledSection>
//           <ExploreSolutionwrapper>
//             <ExploreSolutionImage fluid={aboutData?.explore_more_image}>
//               <ExploreSolutionHeader
//                 css={css`
//                   color: #fff;
//                   width: 49.875rem;
//                   margin-bottom: 3.125rem;
//                   text-align: center;
//                   @media ${medium} {
//                     width: 22rem;
//                   }
//                   @media ${semiLarge} {
//                     width: 26rem;
//                   }
//                   @media ${small} {
//                     width: 100%;
//                     padding: 0 1.5rem;
//                     font-size: 30px;
//                   }
//                 `}
//               >
//                 {aboutData && aboutData.explore_more_text
//                   ? aboutData.explore_more_text
//                   : ""}
//               </ExploreSolutionHeader>
//               <Link to="/products">
//                 <newGlobalStyles.submitButton
//                   css={css`
//                     color: #2d2f44;
//                   `}
//                 >
//                   EXPLORE OUR SOLUTIONS
//                 </newGlobalStyles.submitButton>
//               </Link>
//             </ExploreSolutionImage>
//           </ExploreSolutionwrapper>
//         </newGlobalStyles.StyledSection>
//       )} */}
//       {/* <newGlobalStyles.StyledSection bgcolor="#fff">
//         <newGlobalStyles.DescriptionSection
//           mobMaxWidth="100%"
//           mobWidth="100%"
//           css={css`
//             padding: 10.37rem 0 7rem 0;
//             @media ${small} {
//               padding: 6.25rem 5% 0rem 5%;
//             }
//           `}
//         >
//           <LeaderShip title={"Leadership Team"} />
//         </newGlobalStyles.DescriptionSection>
//       </newGlobalStyles.StyledSection> */}
//     </Layout>
//   )
// }
import React, { useEffect, useState } from "react"
import "../index.css"
import Ustlogo from "../assets/logos/UST-evolve-logo.svg"
import PiktorLogo from "../assets/logos/piktorlabs-logo-updated.svg"

function About() {
  const [counter, updateCounter] = useState(4)
  const [coverClass, updateCoverClass] = useState("cover")
  const [middleDivClass, updateMiddleDivClass] = useState("middle-div")
  useEffect(() => {
    if (counter >= 0) {
      setTimeout(() => {
        if (counter > 0) updateCounter(counter - 1)
        if (counter === 4) {
          updateCoverClass("cover fadeOut-four")
        }
        // if (counter === 4) {
        //   updateCoverClass("cover fadeOut-three")
        // } else if (counter === 3) {
        //   // updateCoverClass("cover fadeOut-two")
        // } else if (counter === 2) {
        //   updateCoverClass("cover fadeOut-one")
        // } else
        else if (counter === 1) {
          // updateCoverClass("cover fadeOut-zero")
          updateMiddleDivClass("middle-div middle-div-to-center")
        } else if (counter === 0) {
          window.location.replace("https://www.ust.com/en/evolve")
        }
      }, 2000)
    }
  })

  return (
    <div className="App">
      <div className={coverClass}></div>
      <div className="logo">
        <img src={Ustlogo} />
      </div>

      <div className={middleDivClass}>
        <div className="vertical-line">
          <img src={PiktorLogo} />
        </div>
        <div className="right-div">
          <div className="right-div-header">
            Farewell, Piktorlabs! Now part of UST Evolve.
          </div>
          <div className="right-div-middle">
            <p>{counter}</p>
          </div>
          <div className="right-div-footer">
            click <a href="https://www.ust.com/en/evolve">here</a> to visit the
            UST home page.
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
